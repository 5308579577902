import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Card = styled.div`
  text-decoration: none;
  background: white;
  color: inherit;
  border: 1px solid var(--lightGrey);
  border-radius: var(--borderRadius);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.15s ease-in-out;
  overflow: hidden;
  &:hover,
  &:focus {
    transform: translateY(-1px);
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
    [class*="CardTitle"] {
      text-decoration: underline;
    }
  }
`

const CardImage = styled.div`
  margin: 0 0 2rem 0;
  width: 100%;
  height: 15rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const CardTitle = styled.h3`
  margin: 0;
`

const CardContent = styled.div`
  padding: 0 2.5rem 2.5rem;
`

const CardCategory = styled.div`
  font-size: 1.2rem;
  min-height: 1.2em;
  line-height: 1;
  margin: 0.5em 0;
`

const CardExcerpt = styled.div`
  color: black;
`

const SingleCard = ({
  cardImage,
  title,
  excerpt,
  slug,
  categories = [],
  className = '',
  ...props
}) => (
  <Card>
    <Link to={slug} className={`Card ${className}`}>
      {cardImage && (
        <CardImage style={{ backgroundImage: `url(${cardImage})` }}>
        </CardImage>
      )}
      <CardContent>
        {title && <CardTitle>{title}</CardTitle>}
        <CardCategory>
          {categories && categories.map(cat => cat.category).join(', ')}
        </CardCategory>
        {excerpt && <CardExcerpt>{excerpt}</CardExcerpt>}
      </CardContent>
    </Link>
  </Card>
)

export default SingleCard
