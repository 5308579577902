import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import PageHero from '../components/PageHero'
import Card from '../components/Card'
import HomepageGrid from '../components/HomepageGrid'

const HomepageIntro = styled.div`
  width: 100%;
  max-width: var(--bp-xlarge);
  margin: 0 auto;
  text-align: center;
  padding: 4rem 0;
`

const HomepageTemplate = () => (
  <StaticQuery
    query={graphql`
      {
        markdownRemark(frontmatter: {template: {eq: "HomePage"}}) {
          frontmatter {
            subtitle
            hero_title
            hero_image
            homepage_intro
            Card_1 {
              card_one_image
              card_one_link
              card_one_snippet
              card_one_title
            }
            Card_2 {
              card_two_image
              card_two_link
              card_two_snippet
              card_two_title
            }
            Card_3 {
              card_three_image
              card_three_link
              card_three_snippet
              card_three_title
            }
            Card_4 {
              card_four_image
              card_four_link
              card_four_snippet
              card_four_title
            }
            Card_5 {
              card_five_image
              card_five_link
              card_five_snippet
              card_five_title
            }
            Card_6 {
              card_six_image
              card_six_link
              card_six_snippet
              card_six_title
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <PageHero title={data.markdownRemark.frontmatter.hero_title} body={data.markdownRemark.frontmatter.subtitle} image={data.markdownRemark.frontmatter.hero_image} />
        { data.markdownRemark.frontmatter.homepage_intro && (
          <HomepageIntro>
            <p> {data.markdownRemark.frontmatter.homepage_intro} </p>
          </HomepageIntro>
        )}
        <HomepageGrid>
          <Card 
            cardImage={data.markdownRemark.frontmatter.Card_1.card_one_image.replace('/htdocs/static/', '/')}
            title={data.markdownRemark.frontmatter.Card_1.card_one_title}
            slug={data.markdownRemark.frontmatter.Card_1.card_one_link}
            excerpt={data.markdownRemark.frontmatter.Card_1.card_one_snippet}
            key={data.markdownRemark.frontmatter.Card_1.card_one_title}
          />
          <Card 
            cardImage={data.markdownRemark.frontmatter.Card_2.card_two_image.replace('/htdocs/static/', '/')}
            title={data.markdownRemark.frontmatter.Card_2.card_two_title}
            slug={data.markdownRemark.frontmatter.Card_2.card_two_link}
            excerpt={data.markdownRemark.frontmatter.Card_2.card_two_snippet}
            key={data.markdownRemark.frontmatter.Card_2.card_two_title}
          />
          <Card 
            cardImage={data.markdownRemark.frontmatter.Card_3.card_three_image.replace('/htdocs/static/', '/')}
            title={data.markdownRemark.frontmatter.Card_3.card_three_title}
            slug={data.markdownRemark.frontmatter.Card_3.card_three_link}
            excerpt={data.markdownRemark.frontmatter.Card_3.card_three_snippet}
            key={data.markdownRemark.frontmatter.Card_3.card_three_title}
          />
          <Card 
            cardImage={data.markdownRemark.frontmatter.Card_4.card_four_image.replace('/htdocs/static/', '/')}
            title={data.markdownRemark.frontmatter.Card_4.card_four_title}
            slug={data.markdownRemark.frontmatter.Card_4.card_four_link}
            excerpt={data.markdownRemark.frontmatter.Card_4.card_four_snippet}
            key={data.markdownRemark.frontmatter.Card_4.card_four_title}
          />
          <Card 
            cardImage={data.markdownRemark.frontmatter.Card_5.card_five_image.replace('/htdocs/static/', '/')}
            title={data.markdownRemark.frontmatter.Card_5.card_five_title}
            slug={data.markdownRemark.frontmatter.Card_5.card_five_link}
            excerpt={data.markdownRemark.frontmatter.Card_5.card_five_snippet}
            key={data.markdownRemark.frontmatter.Card_5.card_five_title}
          />
          <Card 
            cardImage={data.markdownRemark.frontmatter.Card_6.card_six_image.replace('/htdocs/static/', '/')}
            title={data.markdownRemark.frontmatter.Card_6.card_six_title}
            slug={data.markdownRemark.frontmatter.Card_6.card_six_link}
            excerpt={data.markdownRemark.frontmatter.Card_6.card_six_snippet}
            key={data.markdownRemark.frontmatter.Card_6.card_six_title}
          />
        </HomepageGrid>
      </>
    )}></StaticQuery>
)


export default HomepageTemplate