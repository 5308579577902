import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'

const PageHeroComponent = styled.div`
  width: 100%;
  position: relative;
  padding: 15rem 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  img {
    width: 100%;
  }
`

const PageHeroComponentContent = styled.div`
  width: 100%;
  max-width: var(--bp-xlarge);
  margin: 0 auto;
  padding: 0 2rem;
  color: #fff;
  @media screen and (min-width: 1024px) {
    padding: 0 1rem;
  }
  h1 {
    font-size: 6rem;
  }
`

export default function PageHero({
  image,
  imageAlt,
  title,
  body
}) {
  return (
    <PageHeroComponent className="vulcanr" style={{ backgroundImage: `url(${image})` }}>
      <PageHeroComponentContent>
        <h1>{ title }</h1>
        <p>{ body }</p>
      </PageHeroComponentContent>
    </PageHeroComponent>
  )
}