import React from "react"
import '../css/global.css'
import styled from 'styled-components'

const Grid = styled.div`
  width: 100%;
  max-width: var(--bp-xlarge);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto 4rem auto;
  padding: 0 2rem;
  @media screen and (min-width: 1024px) {
    padding: 0 1rem;
  }
  > * {
    width: 100%;
    margin-bottom: 2rem;
    @media screen and (min-width: 1024px) {
      width: calc(100% / 3 - 2rem);
    }
  }
`

export default function HomepageGrid({ children }) {
  return (
    <Grid>
      {children}
    </Grid>
  )
}