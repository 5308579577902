import * as React from "react"
import Layout from "../components/Layout"
import HomepageTemplate from "../templates/Homepage"


const IndexPage = () => {
  return (
    <Layout>
      <HomepageTemplate />
    </Layout>
  )
}

export default IndexPage
